import { fetchPaymentHistory, fetchReceipt } from '@/api/receipt'

const state = {
  // 收据
  receipts: [],
}

const mutations = {
  SET_RECEIPTS: (state, receipts) => {
    state.receipts = receipts
  },
}

const actions = {
  async fetchPaymentHistory({ commit }, args) {
    const { schoolId, studentId } = args
    const response = await fetchPaymentHistory(schoolId, studentId).then(
      (res) => res?.data
    )

    commit('SET_RECEIPTS', response)
  },
  async fetchReceipt(_, args) {
    const { orderId } = args
    const response = await fetchReceipt(orderId).catch((res) => res)

    return response
  },
  resetReceipts({ commit }) {
    commit('SET_RECEIPTS', [])
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
