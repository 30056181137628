import request from '@/utils/request'

export function fetchBill(schoolId, studentId) {
  return request({
    url: `/schools/${schoolId}/students/${studentId}/bill`,
    method: 'get',
  })
}

export function fetchBillQuery(schoolId, studentId) {
  return request({
    url: `/schools/${schoolId}/students/${studentId}/bill/query`,
    method: 'get',
  })
}

export function createOrder(schoolId, studentId, items) {
  return request({
    url: `/schools/${schoolId}/students/${studentId}/order`,
    method: 'post',
    data: {
      items,
    },
  })
}
