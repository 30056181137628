import qs from 'qs'
import wechatAuth from './plugins/wechatAuth' // 微信登录插件
import aliPayAuth from './plugins/aliPayAuth' // 支付宝登录插件
import router from './router'
import store from './store'
import { api } from './config'
import { getPaymentEnv, PaymentEnv } from './utils/index'

const paymentEnv = getPaymentEnv()

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const loginStatus = Number(store.getters.loginStatus || 0)
  const auth = paymentEnv === PaymentEnv.WECHAT ? wechatAuth : aliPayAuth

  if (loginStatus === 0) {
    // 未授权登录跳转到授权登录页面
    const url = window.location.href
    // 解决重复登录url添加重复的code与state问题
    const parseUrl = qs.parse(url.split('?')[1])

    if (parseUrl.code && parseUrl.state) {
      delete parseUrl.code
      delete parseUrl.state
    }

    // 设置授权回调地址
    auth.redirect_uri = api.redirect_uri + to.fullPath
    // 无论拒绝还是授权都设置成1
    store.dispatch('user/setLoginStatus', 1)
    // 跳转到授权页面
    window.location.href = auth.authUrl
  } else if (loginStatus === 1) {
    // 用户已授权，获取code
    try {
      // 通过回调链接设置code status
      auth.getCodeFromRedirectURI(to.fullPath)
    } catch (err) {
      // 失败，设置状态未登录，刷新页面
      store.dispatch('user/setLoginStatus', 0)
      location.reload()
    }
    // 同意授权 to.fullPath 携带code参数，拒绝授权没有code参数
    const code = auth.code
    if (code) {
      // 拿到code 访问服务端的登录接口
      store
        .dispatch('user/loginAuth', {
          code,
          paymentEnv,
        })
        .then(() => {
          // 成功设置已登录状态
          store.dispatch('user/setLoginStatus', 2)
          next()
        })
        .catch(() => {
          // 失败，设置状态未登录，刷新页面
          store.dispatch('user/setLoginStatus', 0)
          location.reload()
        })
    } else {
      store.dispatch('user/setLoginStatus', 0)
      location.reload()
    }
  } else {
    // 已登录直接进入
    next()
  }
})
