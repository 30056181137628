import request from '@/utils/request'

export function fetchPaymentHistory(schoolId, studentId) {
  return request({
    url: `/schools/${schoolId}/students/${studentId}/payment-history/internal`,
    method: 'get',
  })
}

export function fetchReceipt(orderId) {
  return request({
    url: `/orders/${orderId}/receipt`,
    method: 'get',
  })
}
