<template>
  <div class="cost-container">
    <div class="header">
      <div class="info">
        {{ school.name }}
        <span>{{ student.name }}</span>
      </div>
      <div class="tip">班级：{{ student.class_in }}</div>
    </div>
    <div class="content">
      <block v-if="receipts.length">
        <div class="item-wrap">
          <block v-for="receipt in receipts" :key="receipt.key">
            <div class="item">
              <div class="item-header">
                <text>订单号：</text>
                <text>{{ receipt.no }}</text>
              </div>
              <div class="item-header">
                <text>订单金额：</text>
                <text>￥{{ receipt.paid_amount }}</text>
              </div>
              <div class="item-header">
                <text>交易时间：</text>
                <text>{{ receipt.paid_at }}</text>
              </div>
              <div class="item-header preview">
                <text @click="showImage(receipt.id)">查看收据</text>
              </div>
            </div>
          </block>
        </div>
      </block>
      <div v-else class="noTip">
        暂无收据记录！
      </div>
    </div>
    <div class="btn">
      <van-button block plain type="primary" to="home">
        返回首页
      </van-button>
    </div>
    <van-popup v-model:show="show">
      <van-image width="300px" height="200px" fit="contain" :src="receiptImg" />
    </van-popup>
  </div>
</template>
<script>
import { Button, Checkbox, Field, Popup, Image, Dialog } from 'vant'

export default {
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Image.name]: Image,
  },

  data() {
    return {
      show: false,
      receiptImg: '',
    }
  },

  computed: {
    receipts: function() {
      return this.$store.getters.receipts
    },
    school: function() {
      return this.$store.getters.school
    },
    student: function() {
      return this.$store.getters.student
    },
  },

  methods: {
    showImage(orderId) {
      this.$store
        .dispatch('receipt/fetchReceipt', {
          orderId,
        })
        .then((res) => {
          if (res.data.message) {
            Dialog.alert({
              title: '提示',
              message: res.data.message,
              showConfirmButton: true,
            })
            return
          }
          this.show = true
          this.receiptImg = res.data.img
        })
    },
  },

  mounted() {
    const { school, student } = this.$store.getters
    this.$store.dispatch('receipt/resetReceipts')
    this.$store.dispatch('receipt/fetchPaymentHistory', {
      schoolId: school.id,
      studentId: student.id,
    })
  },
}
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 316px;
  background: url(../assets/cost_bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 100px 0;
  .info {
    font-size: 36px;
    color: #333;
    padding-bottom: 20px;
    margin: 0 50px;
    border-bottom: 1px solid #ccc;
    span {
      padding-left: 16px;
    }
  }
  .tip {
    font-size: 26px;
    padding-top: 20px;
  }
}
.content {
  box-sizing: border-box;
  padding: 26px;
}
.item-wrap {
  .item {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px;
      margin-bottom: 32px;
    }
    .item-header {
      font-size: 30px;
      color: #333;
      div:first-child {
        display: flex;
        text {
          padding-left: 12px;
        }
      }
    }
    .preview {
      color: #1989fa;
    }
    .item-tip {
      padding-left: 46px;
      font-size: 26px;
      color: #666;
    }
  }
}
.noTip {
  line-height: 400px;
  text-align: center;
  font-size: 36px;
  color: #63ca63;
}
.btn {
  padding: 0 26px;
  button {
    margin-top: 60px;
    border-radius: 16px;
  }
}
</style>
