import qs from 'qs'

const SCOPE = ['auth_base']

class VueAliPayAuthPlugin {
  constructor() {
    this.appid = null
    this.redirect_uri = null
    this._code = null
    this._redirect_uri = null
  }

  install(Vue, options) {
    const aliPayAuth = this
    this.setAppId(options.appid)
    Vue.mixin({
      created: function() {
        this.$aliPayAuth = aliPayAuth
      },
    })
  }

  setAppId(appid) {
    this.appid = appid
  }

  set redirect_uri(redirect_uri) {
    this._redirect_uri = encodeURIComponent(redirect_uri)
  }

  get redirect_uri() {
    return this._redirect_uri
  }

  get state() {
    return localStorage.getItem('aliPay_auth:state')
  }

  set state(state) {
    localStorage.setItem('aliPay_auth:state', state)
  }

  get authUrl() {
    if (this.appid === null) {
      throw new Error('appid must not be null')
    }
    if (this.redirect_uri === null) {
      throw new Error('redirect uri must not be null')
    }
    return `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${this.appid}&redirect_uri=${this.redirect_uri}&scope=${SCOPE[0]}`
  }

  getCodeFromRedirectURI(redirect_uri) {
    const parsedUrl = qs.parse(redirect_uri.split('?')[1])
    this._code = parsedUrl.auth_code
  }

  get code() {
    if (this._code === null) {
      throw new Error('Not get the code from aliPay server!')
    }
    const code = this._code
    this._code = null
    return code
  }
}

const vueAliPayAuthPlugin = new VueAliPayAuthPlugin()

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueAliPayAuthPlugin)
}

export default vueAliPayAuthPlugin
