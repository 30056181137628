const getters = {
  // user
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  loginStatus: (state) => state.user.loginStatus,
  school: (state) => state.home.school,
  schoolNo: (state) => state.home.schoolNo,
  student: (state) => state.home.student,
  allow_payments: (state) => state.home.allow_payments,
  costs: (state) => state.cost.costs,
  year: (state) => state.cost.year,
  items: (state) => state.cost.items,
  unpaidCosts: (state) => state.cost.unpaidCosts,
  unpaidCostsTotal: (state) => state.cost.unpaidCostsTotal,
  total: (state) => state.cost.total,
  receipts: (state) => state.receipt.receipts,
}

export default getters
