import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import wechatAuth from './plugins/wechatAuth'
import aliPayAuth from './plugins/aliPayAuth'

createApp(App)
  .use(wechatAuth, {
    appid: 'wx5bb16ec6a0fc9720',
  })
  .use(aliPayAuth, {
    appid: '2021003199673232',
  })
  .use(router)
  .use(store)
  .mount('#app')
