import {
  fetchSchool,
  fetchSchoolStudent,
  fetchSystemSettings,
  bindStudent,
  unbindStudent,
} from '@/api/home'
import router from '@/router'
import { Dialog } from 'vant'

const state = {
  school: '',
  student: '',
  schoolNo: '',
  allow_payments: true,
}

const mutations = {
  SET_SCHOOL: (state, school) => {
    state.school = school
  },
  SET_STUDENT: (state, student) => {
    state.student = student
  },
  SET_SCHOOL_NO: (state, schoolNo) => {
    state.schoolNo = schoolNo
  },
  SET_ALLOW_PAYMENTS: (state, allow_payments) => {
    state.allow_payments = allow_payments
  },
}

const actions = {
  async fetchSchool({ commit }, params) {
    const response = await fetchSchool(params).catch((res) => res)
    if (response.status >= 300) {
      Dialog.alert({
        title: '提示',
        message: response.data.message,
        showConfirmButton: false,
      })
      return
    }
    const school = response.data
    commit('SET_SCHOOL', school)
    const students = await fetchSchoolStudent(school.id).then((res) => res.data)
    if (!students.length) {
      router.push('/login')
    } else {
      commit('SET_STUDENT', students[0])
    }
    return school
  },
  async fetchSystemSettings({ commit, state }) {
    const response = await fetchSystemSettings(state.school.id)
    const { allow_payments } = response.data.settings
    commit('SET_ALLOW_PAYMENTS', allow_payments)
    return response.data.settings
  },
  bindStudent({ state }, params) {
    bindStudent(state.school.id, params)
      .then(() => router.push('/home'))
      .catch((res) => {
        Dialog.alert({
          title: '登录失败',
          message: res.data.message,
          confirmButtonColor: '#1989fa',
        })
      })
  },
  async unbindStudent({ state }) {
    const response = await unbindStudent(
      state.school.id,
      state.student.id
    ).catch((res) => res)
    if (response.status >= 300) {
      Dialog.alert({
        title: '提示',
        message: response.data.message,
        confirmButtonColor: '#1989fa',
      })
      return
    }
    Dialog.alert({
      title: '解绑提示',
      message: '解绑成功',
      confirmButtonColor: '#1989fa',
    }).then(() => {
      router.push('/login')
    })
  },
  setSchoolNo({ commit }, query) {
    commit('SET_SCHOOL_NO', query)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
