<template>
  <div class="container">
    <div class="section">
      <div class="section-title">报名缴费</div>
      <div class="section-item-wrap">
        <div class="section-item" @click="goToCost">
          <img src="../assets/cost.png" />
          <div>学杂费</div>
        </div>
        <div class="section-item" @click="tip">
          <img src="../assets/other_cost.png" />
          <div>其他费用</div>
        </div>
        <div class="section-item" @click="goToCostQuery">
          <img src="../assets/icon4.png" />
          <div>欠费查询</div>
        </div>
        <div class="section-item" @click="tip">
          <img src="../assets/icon3.png" />
          <div>报名考试</div>
        </div>
        <div class="section-item" @click="tip">
          <img src="../assets/icon4.png" />
          <div>报名查询</div>
        </div>
        <div class="section-item" @click="goToHistory">
          <img src="../assets/history.png" />
          <div>缴费记录</div>
        </div>
        <div class="section-item" @click="tip">
          <img src="../assets/icon5.png" />
          <div>银行卡提报</div>
        </div>
        <div class="section-item" @click="unbind">
          <img src="../assets/unbind.png" />
          <div>身份解绑</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-title">发票服务</div>
      <div class="section-item-wrap">
        <div class="section-item" @click="tip">
          <img src="../assets/icon6.png" />
          <div>发票申请</div>
        </div>
        <div class="section-item" @click="tip">
          <img src="../assets/icon7.png" />
          <div>发票查询</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-title">其他服务</div>
      <div class="section-item-wrap">
        <div class="section-item" @click="tip">
          <img src="../assets/icon8.png" />
          <div>毕业查询</div>
        </div>
        <div v-if="showBill" class="section-item" @click="goToReceiptQuery">
          <img src="../assets/icon4.png" />
          <div>收据查询</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Grid, GridItem, Dialog } from 'vant'
import dayjs from 'dayjs'

export default {
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Dialog.name]: Dialog,
  },

  computed: {
    school: function () {
      return this.$store.getters.school
    },
    // 是否显示收据查询
    showBill: function () {
      return this.school.id === 9
    },
  },

  methods: {
    tip() {
      Dialog.alert({
        title: '消息提示',
        message: '该功能暂为开放',
        confirmButtonColor: '#1989fa',
      })
    },
    goToCost() {
      this.$store.dispatch('home/fetchSystemSettings').then((res) => {
        const { open, start_time, end_time } = res
        if (!open) {
          Dialog.alert({
            title: '消息提示',
            message: '缴费时间关闭！',
            confirmButtonColor: '#1989fa',
          })
          return
        }
        const now = dayjs().format('HH:mm')
        if (now < start_time || now > end_time) {
          Dialog.alert({
            title: '消息提示',
            message: `缴费时间段：${start_time}—${end_time}`,
            confirmButtonColor: '#1989fa',
          })
          return
        }
        this.$router.push('costs')
      })
    },
    goToCostQuery() {
      this.$router.push('costQuery')
    },
    goToReceiptQuery() {
      this.$router.push('receiptQuery')
    },
    goToHistory() {
      this.$router.push('paymentHistory')
    },
    unbind() {
      Dialog.confirm({
        title: '提示',
        message: '确认解绑身份吗？',
        confirmButtonColor: '#1989fa',
      })
        .then(() => {
          this.$store.dispatch('home/unbindStudent')
        })
        .catch(() => {})
    },
  },

  mounted() {
    const { no, sn } = this.$route.query
    if (no) {
      this.$store.dispatch('home/setSchoolNo', no)
    }
    const schoolNo = no || this.$store.getters.schoolNo
    const schoolSn = sn
    this.$store
      .dispatch('home/fetchSchool', { schoolNo, schoolSn })
      .then((res) => {
        const schoolName = res.name
        document.title = schoolName
      })
  },
}
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  background: #eee;
  .section {
    background: #fff;
    margin-bottom: 20px;
    .section-title {
      position: relative;
      line-height: 84px;
      box-sizing: border-box;
      padding-left: 38px;
      font-size: 34px;
      color: #333;
      &:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 24px;
        background: #4888f4;
        margin-right: 20px;
      }
      &:after {
        position: absolute;
        content: '';
        background-color: #bfbfbf;
        display: block;
        height: 2px;
        transform: scale(1, 0.5);
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .section-item-wrap {
      .section-item {
        display: inline-block;
        box-sizing: border-box;
        height: 174px;
        width: 186px;
        text-align: center;
        padding-top: 50px;
        img {
          width: 46px;
          height: 46px;
        }
        div {
          font-size: 24px;
          color: #333;
          padding-top: 16px;
        }
      }
    }
  }
}
</style>
