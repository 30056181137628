// 获取支付环境即 H5 页面是在微信环境还是支付宝环境
export function getPaymentEnv() {
  const userAgent = window.navigator.userAgent.toLowerCase()

  if (userAgent.indexOf('micromessenger') != -1) {
    // 用户在微信中打开
    return PaymentEnv.WECHAT
  } else if (userAgent.indexOf('alipayclient') != -1) {
    // 用户在支付宝中打开
    return PaymentEnv.ALIPAY
  } else {
    return PaymentEnv.WECHAT
  }
}

// 支付环境
export const PaymentEnv = {
  ALIPAY: 'alipay',
  WECHAT: 'wechat',
}
