import qs from 'qs'
import request from '@/utils/request'
import { PaymentEnv } from '@/utils/index'

/**
 * 登录接口请求token与userinfo
 * @param params {code: code}
 */
export function loginByCode(params) {
  const { code, paymentEnv } = params || {}
  const grant_type =
    paymentEnv === PaymentEnv.WECHAT ? 'wechat-social' : 'alipay-social'
  const social_scope =
    paymentEnv === PaymentEnv.WECHAT ? 'snsapi_base' : 'auth_base'
  return request({
    url: '/authorizations',
    method: 'post',
    data: qs.stringify({
      code,
      client_id: '938dbf7d-83d3-4533-94a4-b0721fb346ae',
      client_secret: 'PH6rgTnulI71PNKTj0s7EFwfJHnjrPwTmaKqGH0a',
      grant_type,
      social_scope,
    }),
  })
}
