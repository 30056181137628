import request from '@/utils/request'

export function fetchSchool({ schoolNo, schoolSn }) {
  let url = `/schools?no=${schoolNo}`
  if (schoolSn !== undefined && schoolSn !== null) {
    url += `&sn=${schoolSn}`
  }
  return request({
    url,
    method: 'get',
  })
}

export function fetchSchoolStudent(id) {
  return request({
    url: `/schools/${id}/students`,
    method: 'get',
  })
}

export function bindStudent(id, params) {
  return request({
    url: `/schools/${id}/students`,
    method: 'POST',
    data: {
      ...params,
    },
  })
}

export function unbindStudent(schoolId, studentId) {
  return request({
    url: `schools/${schoolId}/students/${studentId}`,
    method: 'DELETE',
  })
}

export function fetchSystemSettings(schoolId) {
  return request({
    url: `/schools/${schoolId}/system-settings`,
    method: 'get',
  })
}
