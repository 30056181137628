import { fetchBill, fetchBillQuery, createOrder } from '@/api/cost'
import router from '@/router'

const state = {
  // 费用项
  costs: [],
  year: '',
  items: [],
  unpaidCosts: [],
  unpaidCostsTotal: 0,
  total: 0,
}

const mutations = {
  SET_COSTS: (state, costs) => {
    state.costs = costs
  },
  SET_YEAR: (state, year) => {
    state.year = year
  },
  SET_ITEMS: (state, items) => {
    state.items = items
  },
  SET_UNPAIDCOSTS: (state, unpaidCosts) => {
    state.unpaidCosts = unpaidCosts
  },
  SET_UNPAIDCOSTS_TOTAL: (state, unpaidCostsTotal) => {
    state.unpaidCostsTotal = unpaidCostsTotal
  },
  SET_TOTAL: (state, total) => {
    state.total = total
  },
}

const actions = {
  async fetchBill({ commit }, args) {
    const { schoolId, studentId } = args
    let response = await fetchBill(schoolId, studentId).then((res) => res?.data)

    if (response?.length > 0) {
      response.forEach((cost) => {
        let total = 0
        cost.items = cost.items.filter((item) => item.unpaid_amount > 0)
        cost.items.forEach((item) => (total += Number(item.unpaid_amount)))
        cost.total = total.toFixed(2)
      })
    } else {
      response = []
    }
    commit('SET_COSTS', response)
  },
  handleCost({ commit }, cost) {
    let total = 0
    const temp = cost.items.map((item) => {
      if (item.mandatory) {
        item.checked = true
      }
      if (!item.partial) {
        item.charge_amount = item.unpaid_amount
        if (item.checked) {
          total += Number(item.unpaid_amount)
        }
      }
      item.attach = cost.date

      return { ...item }
    })
    commit('SET_YEAR', cost.year)
    commit('SET_ITEMS', temp)
    commit('SET_TOTAL', total)
    router.push('/cost')
  },
  async fetchBillQuery({ commit }, args) {
    const { schoolId, studentId } = args
    const unpaidCosts = await fetchBillQuery(schoolId, studentId).then(
      (res) => res?.data
    )
    let total = 0
    unpaidCosts.forEach((cost) => (total += Number(cost.unpaid_amount)))
    commit('SET_UNPAIDCOSTS', unpaidCosts || [])
    commit('SET_UNPAIDCOSTS_TOTAL', total.toFixed(2))
  },
  createOrder({ rootState }, params) {
    const {
      home: { school, student },
    } = rootState
    return new Promise((resolve, reject) => {
      createOrder(school.id, student.id, params.items).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  resetCosts({ commit }) {
    commit('SET_COSTS', [])
  },
  setTotal({ commit }, params) {
    commit('SET_TOTAL', params)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
