import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Cost from '../views/Cost.vue'
import CostList from '../views/CostList.vue'
import CostQuery from '../views/CostQuery.vue'
import PaymentResult from '../views/PaymentResult.vue'
import PaymentHistory from '../views/PaymentHistory.vue'
import ReceiptQuery from '../views/ReceiptQuery.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: '登录',
    component: Login,
    meta: {
      title: '登录',
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/costs',
    name: 'costs',
    component: CostList,
    meta: {
      title: '学杂费列表',
    },
  },
  {
    path: '/cost',
    name: 'cost',
    component: Cost,
    meta: {
      title: '学杂费',
    },
  },
  {
    path: '/costQuery',
    name: 'costQuery',
    component: CostQuery,
    meta: {
      title: '欠费查询',
    },
  },
  {
    path: '/paymentHistory',
    name: 'paymentHistory',
    component: PaymentHistory,
    meta: {
      title: '缴费记录',
    },
  },
  {
    path: '/paymentResult',
    name: 'paymentResult',
    component: PaymentResult,
    meta: {
      title: '缴费结果',
    },
  },
  {
    path: '/receiptQuery',
    name: 'receiptQuery',
    component: ReceiptQuery,
    meta: {
      title: '收据查询',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
